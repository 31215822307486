export default (() => {
  class TriviaGames {
    constructor({ selector }) {
      this.elems = [...document.querySelectorAll(selector)]
      if (!this.elems.length) {
        return
      }

      const script = document.createElement('script')
      script.src = this.elems[0].dataset.gameUrl
      this.GAME_URL = script.src
      script.type = 'text/javascript'
      document.head.appendChild(script)

      window.wafer.ready(() => {
        this.init()
      })
    }

    init() {
      const { debounce, loadScriptAsync } = window.wafer.utils

      loadScriptAsync({ src: this.GAME_URL }, () => {
        const initOptions = this.elems.map(elem => this._getTriviaInitOptions(elem))[0]

        window.gameWidget.initialize(initOptions)

        this.pathName = window.location.pathname
        this.triviaGameIframe = document.getElementById(initOptions.containerId)?.firstChild

        if (this.triviaGameIframe) {
          window.addEventListener('resize', debounce(this.resizeAndIntiIframe, 200, this))
          window.addEventListener('load', debounce(this.resizeAndIntiIframe, 200, this))
        } else {
          console.error('Trivia failed to init...')
        }
      })
    }

    _getTriviaInitOptions(elem) {
      const { gameId, clientId, spaceId } = elem.dataset

      const initOptions = {
        containerId: 'game-widget',
        iframeHeight: 450,
        iframeWidth: 330,
        successCallBackFn: () => {},
        locale: 'en_US',
        debugMode: true,
        rapidSpaceId: spaceId,
        clientId,
        gameId
      }

      return initOptions
    }

    resizeAndIntiIframe() {
      const screenWidth = window.innerWidth
      const iFrameContent = this.triviaGameIframe.contentWindow

      if (screenWidth >= 0 && screenWidth < 601) {
        iFrameContent.postMessage({ type: 'resize', value: 'xxs' }, '*')
      } else if (screenWidth > 600 && screenWidth <= 900) {
        // games switch to RR at 754
        if (this.pathName === '/games/' && screenWidth >= 754) {
          iFrameContent.postMessage({ type: 'resize', value: '' }, '*')
        } else {
          iFrameContent.postMessage({ type: 'resize', value: 'xs' }, '*')
        }
      } else {
        iFrameContent.postMessage({ type: 'resize', value: '' }, '*')
      }
    }
  }

  return TriviaGames
})()
